import { css } from '@emotion/css';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import { DataSourceJsonData, DataSourceSettings, GrafanaTheme2 } from '@grafana/data';
import { ConfigSection } from '@grafana/experimental';
import { getBackendSrv } from '@grafana/runtime';
import { useStyles2, VerticalGroup, Button, HorizontalGroup } from '@grafana/ui';
import { contextSrv } from 'app/core/core';
import { DataSourceReadOnlyMessage } from 'app/features/datasources/components/DataSourceReadOnlyMessage';
import { Team } from 'app/types';

import { AccessControlAction as EnterpriseActions, TeamHeader, TeamHeaders } from '../types';

import { AddTeamLBACForm, LBACFormData } from './AddTeamLBACForm';
import { TeamRulesRow } from './TeamRulesRow';
import { extractLBACRule, formatLBACRule, LBACHTTPHeaderName } from './utils';

export interface Props {
  teamHeaders: TeamHeaders;
  dataSourceConfig: DataSourceSettings<DataSourceJsonData, {}>;
  readOnly?: boolean;
  onTeamHeadersUpdate: (headers: TeamHeaders) => Promise<DataSourceSettings> | void;
}

type TeamRulesState = Record<string, string[]>;

export const TeamLBACEditor = ({ teamHeaders = {}, dataSourceConfig, readOnly, onTeamHeadersUpdate }: Props) => {
  const [teams, setTeams] = useState<Array<Pick<Team, 'name' | 'avatarUrl' | 'id'>>>([]);
  const [showLBACForm, setShowLBACForm] = useState(false);
  const [teamsRules, setTeamsRules] = useState<TeamRulesState>({});
  const styles = useStyles2(getStyles);

  useEffect(() => {
    const fetchTeams = async () => {
      const teamIds = Object.keys(teamHeaders);
      if (!teamIds?.length) {
        return;
      }

      const result = await getBackendSrv().get('/api/teams/search', { teamId: teamIds });
      const teamsArray: Team[] = result?.teams;
      const teams = teamsArray.map((team) => ({
        id: team.id,
        value: team.id,
        teamId: team.id,
        name: team.name,
        avatarUrl: team.avatarUrl,
      }));
      setTeams(teams);
    };
    fetchTeams();
  }, [teamHeaders]);

  useEffect(() => {
    const rules: TeamRulesState = {};
    if (!teamHeaders || !teamHeaders?.headers) {
      return;
    }

    Object.keys(teamHeaders?.headers!)?.forEach((key) => {
      const teamId = key;
      if (!teamHeaders?.headers![teamId]) {
        return;
      }

      teamHeaders.headers![teamId].forEach((h) => {
        if (rules[teamId]) {
          rules[teamId].push(extractLBACRule(h.value));
        } else {
          rules[teamId] = [extractLBACRule(h.value)];
        }
      });
    });

    setTeamsRules(rules);
  }, [teamHeaders]);

  const toTeamHeaders = useCallback(
    (teamsLBACRules: TeamRulesState): TeamHeaders => {
      const teamHeaders: { [K: number | string]: TeamHeader[] } = {};
      const tenantId = dataSourceConfig.basicAuthUser;
      Object.keys(teamsLBACRules).forEach((key) => {
        const teamId = Number(key);
        const rules = teamsLBACRules[teamId];
        rules.forEach((rule) => {
          const header = { header: LBACHTTPHeaderName, value: formatLBACRule(rule, tenantId) };
          teamHeaders[teamId] = teamHeaders[teamId] ? teamHeaders[teamId].concat(header) : [header];
        });
      });
      const teamHeadersJSON: TeamHeaders = {
        headers: teamHeaders,
      };
      return teamHeadersJSON;
    },
    [dataSourceConfig]
  );

  const onTeamHeadersUpdateInternal = useCallback(
    async (headers: TeamHeaders) => {
      return onTeamHeadersUpdate(headers);
    },
    [onTeamHeadersUpdate]
  );

  const onChangeInternal = useCallback(
    (teamsRules: TeamRulesState) => {
      onTeamHeadersUpdate({ ...toTeamHeaders(teamsRules) });
    },
    [onTeamHeadersUpdate, toTeamHeaders]
  );

  const onSubmitLBAC = async ({ team, rule }: LBACFormData) => {
    const newHeaders = { ...teamHeaders.headers };
    const tenantId = dataSourceConfig.basicAuthUser;
    const header = { header: LBACHTTPHeaderName, value: formatLBACRule(rule, tenantId) };
    newHeaders[team] = newHeaders[team] ? newHeaders[team].concat(header) : [header];
    await onTeamHeadersUpdateInternal({ ...teamHeaders, headers: newHeaders });
    setShowLBACForm(false);
  };

  const onRulesUpdate = async (teamId: string, teamRules: string[]) => {
    setTeamsRules({ ...teamsRules, [teamId]: teamRules });
    onChangeInternal({ ...teamsRules, [teamId]: teamRules });
  };

  const canEdit = contextSrv.hasPermission(EnterpriseActions.DataSourcesPermissionsWrite) && !readOnly;

  return (
    <div>
      {readOnly && <DataSourceReadOnlyMessage />}
      <VerticalGroup spacing={'md'}>
        <ConfigSection
          className={styles.sectionHeader}
          title="Team LBAC"
          description="Configure access to data based on team membership."
        >
          <VerticalGroup spacing={'sm'}>
            {canEdit && (
              <HorizontalGroup>
                <Button onClick={() => setShowLBACForm(true)}>Add LBAC rule</Button>
              </HorizontalGroup>
            )}
            {showLBACForm && <AddTeamLBACForm onSubmit={onSubmitLBAC} onClose={() => setShowLBACForm(false)} />}
          </VerticalGroup>
        </ConfigSection>
        {!isEmpty(teamsRules) && (
          <div className={styles.wrapper}>
            <div>
              <table role="grid" className="filter-table gf-form-group" aria-labelledby="team_lbac_rules">
                <thead>
                  <tr>
                    <th style={{ width: '30%' }}>Team</th>
                    <th style={{ width: '1%' }} />
                    <th>Label selector</th>
                    <th style={{ width: '1%' }} />
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(teamsRules).map((teamId, idx) => {
                    const teamRules = teamsRules[teamId];
                    return (
                      <TeamRulesRow
                        key={idx}
                        teamRules={teamRules}
                        team={
                          teams.find((t) => t.id?.toString() === teamId) || {
                            id: Number(teamId),
                            name: '',
                            avatarUrl: '',
                          }
                        }
                        onChange={(teamRules) => onRulesUpdate(teamId, teamRules)}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </VerticalGroup>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css({
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
      overflowY: 'hidden',
      minHeight: '100vh',
      width: '100%',
      '& > div': {
        overflowX: 'unset',
        marginBottom: theme.spacing(2),
      },
      '& > td': {
        padding: theme.spacing(1), // Adjust the padding as needed
      },
    }),
    sectionHeader: css({
      marginBottom: theme.spacing(2),
    }),
  };
};
